import React, { useMemo, useState } from "react";
import { Button } from "antd";
import Slider from "react-slick";
import { LeftOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons';

export const BannerChangelog = ({
	apps,
	data,
	seeMore,
	setSeeMore,
	itemSelect,
	setItemSelect,
	detailSelect,
	setDetailSelect,
	buttonSeeMore,
}) => {

	const [listVersions, setListVersions] = useState([])

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const handleButtonAction = (key) => {
		let select = apps.nodes.find(item => item.key === key)
		setItemSelect(select)
	}

	useMemo(() => {
		let listVersions = data.nodes.filter(item =>
			item.app.key === itemSelect.key
		)
		setDetailSelect(listVersions[0])
		setListVersions(listVersions)
	}, [itemSelect])

	return (
		<div className="BannerChangelog">
			<div className="BannerChangelog__background"></div>
			<div className="BannerChangelog__container">
				<div className="BannerChangelog__container--left">
					{!seeMore &&
						<>
							<h4>Change Log LIFTIT</h4>
							<div className="btns">
								{apps.nodes?.map((item, index) =>
									<Button onClick={() => handleButtonAction(item.key)} key={index} className={`btn  ${itemSelect.key === item.key && "btn--active"}`}>
										{item.name}
									</Button>
								)}
							</div>
							<h1>{itemSelect && itemSelect.name}</h1>
							<p>{itemSelect && itemSelect.description}</p>
							<div className="cont-btn">
								{buttonSeeMore}
							</div>
						</>
					}
					{seeMore &&
						<div className="card-more">
							<Button className="back" onClick={() => setSeeMore(false)}><LeftOutlined /><h4>Change Log LIFTIT</h4></Button>
							<div className="card">
								<div className="card__head">
									<Button><SearchOutlined /></Button>
									<Button><MenuOutlined /></Button>
								</div>
								<div className="card__body">
									{itemSelect &&
										<div>
											<h1>{itemSelect.name}</h1>
											<ul className="list">
												{listVersions?.map((item, index) =>
													<li key={index} className={`list__item${item.version === detailSelect.version ? '__sel' : ''}`}>
														<div className="cont">
															<div className="point">.</div>
															<a className="name">
																{item.name_version + " v" + item.version}
															</a>
														</div>
														<button onClick={() => setDetailSelect(item)} className="see">
															Ver
														</button>
													</li>
												)}
											</ul>
										</div>
									}
								</div>
							</div>
						</div>
					}
				</div>
				<div className="BannerChangelog__container--right">
					<div className="caroucel-changelog">
						<Slider {...settings}>
							{
								apps.nodes?.map((item, index) =>
									<div key={index} className="cont-img">
										<img src={itemSelect.multimedia.src} alt={item.name} className="img-back-office" />
									</div>
								)
							}
						</Slider>
					</div>
				</div>
			</div>
			<div className="msj">
				<h6>
					¡Seguimos evolucionando y mejorando nuestra tecnología!
				</h6>
			</div>
		</div>
	);
} 