import React, { useMemo, useState } from "react"
import { Button } from "antd";
import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"

import { BannerChangelog } from "../../components/PageChangelog/BannerChangelog/BannerChangelog";
import { SectionInfo } from "../../components/PageChangelog/SectionInfo/SectionInfo";
import { SectionManage } from "../../components/PageChangelog/SectionManage/SectionManage";
import { SectionDescription } from "../../components/PageChangelog/SectionDescription/SectionDescription";

const ChangeLog = ({
	data: { allContentfulPaginaChangelog: allContentfulPageChangelog, allContentfulApp }
}) => {

	const [seeMore, setSeeMore] = useState(false);
	const [itemSelect, setItemSelect] = useState(allContentfulApp.nodes[0])
	const [detailSelect, setDetailSelect] = useState(undefined)
	const [moreApps, setMoreApps] = useState(undefined)

	useMemo(() => {
		const detailSelect = allContentfulPageChangelog.nodes.filter(item => item.app.key === itemSelect.key)
		setDetailSelect(detailSelect[0])
	}, [])

	useMemo(() => {
		const moreApps = []
		for (let index = 0; index < allContentfulApp.nodes.length; index++) {
			if (allContentfulApp.nodes[index])
				if (allContentfulApp.nodes[index].key !== itemSelect.key){
					moreApps.push(allContentfulApp.nodes[index])
				}
		}
		setMoreApps(moreApps)
	}, [itemSelect])

	return (
		<Layout>
			<div className="ChangeLog">
				<BannerChangelog
					apps={allContentfulApp}
					data={allContentfulPageChangelog}
					seeMore={seeMore}
					setSeeMore={setSeeMore}
					itemSelect={itemSelect}
					setItemSelect={setItemSelect}
					detailSelect={detailSelect}
					setDetailSelect={setDetailSelect}
					buttonSeeMore={
						<Button className="btn btn--primary" onClick={() => setSeeMore(true)}>
							Ver más
						</Button>
					}
				/>
				{
					detailSelect &&
					<>
						<SectionInfo
							itemSelect={itemSelect}
							setItemSelect={setItemSelect}
							detailSelect={detailSelect}
							setDetailSelect={setDetailSelect}
						/>
						<SectionDescription apps={moreApps} />
					</>
				}
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
query PageChangelogs($locale: String) {
	allContentfulApp {
    nodes {
      key
      name
      description
      multimedia {
        src
			}
			multimedia_footer {
				src
			}
    }
  }
	allContentfulPaginaChangelog(filter: { language: { language: { eq: $locale } } }) {
      nodes {
        name
        seo {
          ...SEOFragment
        }
        app {
          name
					key
					description
					multimedia {
						src
					}
					multimedia_footer {
						src
					}
				}
				name_version
				version
				color
        text_banner_footer
        section_detail_version {
          ...SectionFragment
        }
        section_introduction {
          ...SectionFragment
        }
        question_version {
          ...SectionFragment
        }
        list_improvements {
          ...LinkFragment
        }
        scope_update
        date_version {
          ...LinkFragment
        }
        infographics_text
        link_infographics {
          ...LinkFragment
        }
        detail_infographics {
          ...SectionFragment
        }
        section_recommendations {
          ...SectionFragment
				}
				link_platform {
					...SectionFragment
				}
        section_final {
          ...SectionFragment
        }
      }
	}
}

`

export default ChangeLog
