import { Button } from "antd";
import React from "react";
import moment from "moment"
import ReactMarkdown from 'react-markdown'
import { CalendarOutlined, ClockCircleOutlined, PlusOutlined } from '@ant-design/icons';

import logo from "../../../assets/images/logo-ligth.png";
import cua from "../../../assets/images/cua.png";
import perspectiva from "../../../assets/images/Perspectiva.png";

export const SectionInfo = ({
	itemSelect,
	setItemSelect,
	detailSelect,
	setDetailSelect,
}) => {

	const {
		version,
		section_detail_version,
		section_introduction,
		question_version,
		list_improvements,
		scope_update,
		date_version,
		link_platform,
		section_recommendations,
		section_final,
		infographics_text,
		link_infographics,
		detail_infographics
	} = detailSelect

	return (
		<div className="section-info">
			<div className="section-info__container">
				<div className="section-info__container--head" style={{ backgroundColor: `${detailSelect.color}` }}>
					{detailSelect &&
						<div className="cont">
							<div className="image">
								<img src={section_detail_version?.image} alt="imagen" className="imagen" />
								{/* <img src="https://res.cloudinary.com/arepa-tec/image/upload/v1603167983/lifters/Group_336_ok5n0c.png" alt="imagen" className="imagen" /> */}
							</div>
							<div className="content">
								<img className="logo" src={logo} alt="logo" />
								<h1>{section_detail_version.title}</h1>
								<div className="cont-version">
									<Button className="btn-v">
										Versión
									</Button>
									<span className="span">{version}</span>
								</div>
								<p>{section_detail_version.description.description}</p>
							</div>

						</div>
					}
				</div>
				<div className="section-info__container--body">
					{
						section_introduction &&
						<>
							<h2 style={{ fontSize: '21px' }} className="body-title">
								{section_introduction.title}
							</h2>
							<p className="body-text">
								{section_introduction.description.description}
							</p>
						</>
					}
					{
						question_version &&
						question_version.map(item =>
							<>
								<h3 className="body-title">
									{item.title}
								</h3>
								<p className="body-text">
									{item.description.description}
								</p>
							</>
						)
					}
					<h3 className="body-title">
						Desplegamos las siguientes mejoras:
					</h3>
					{
						list_improvements &&
						list_improvements.map((item, index) =>
							<>
								<p className="body-text">
									<span className="num">{index + 1}.</span>
									{item.label}
								</p>
							</>
						)
					}
					{
						scope_update &&
						<div className="text-mes">
							<p><span>Alcance de la actualización: </span>{scope_update}</p>
						</div>
					}

					{
						date_version &&
						<div className="cards">
							{
								date_version.map((item, index) =>
									<div className="card-date">
										<div>
											{index === 0 ? <CalendarOutlined /> : <ClockCircleOutlined />}
										</div>
										<div className="">
											<p>{item.label}</p>
											<h3 className="body-title">
												{index === 0 ? moment(item.date_start).format('MMMM Do YYYY') : 'de 9 p.m a 12 p.m.'}
											</h3>
										</div>
									</div>
								)
							}
						</div>
					}{/* 
					<div className="card-date">
						<div>
							<ClockCircleOutlined />
						</div>
						<div className="">
							<p>Ventana de mantenimiento para despliegue:</p>
							<h3 className="body-title">
								de 9 p.m a 12 p.m.
								</h3>
						</div>
					</div> */}

					<div className="text-img">
						<img src={cua} alt="logoInfografias" />
						<p>
							Conoce acerca de los cambios realizados en nuestra plataforma en las siguientes infografías interactivas:
						</p>
					</div>

					<p className="body-text">
						<span className="point">.</span>
						{infographics_text}
					</p>
					<div style={{ margin: '25px 0px' }} className="links">
						{
							link_infographics && link_infographics.map((item, index) =>
								<a key={index} target="_blank" href={item.link.link} className="link">{item.label}</a>
							)
						}
					</div>

					{
						detail_infographics &&
						<div className="items">
							<h2 style={{ fontSize: '16px', margin: '50px 20px', maxWidth: '400px', lineHeight: '19px' }} className="body-title">
								{detail_infographics.title}
							</h2>
							<ReactMarkdown className="text" source={detail_infographics.description.description} />
						</div>
					}

					{
						section_recommendations &&
						section_recommendations.map((item, index) =>
							<div key={index} className="items">
								<h4 className="title-orange">{item.title}</h4>
								<ReactMarkdown className="text" source={item.description.description} />
							</div>
						)
					}

					{
						link_platform &&
						link_platform.map(item =>
							<div className="text-img">
								<img src={item.image} alt="logoYoutube" />
								<p>
									{item.title}
								</p>
								<a target="_blank" href={item.link.link.link} className="link">{item.link.label}</a>
							</div>
						)
					}

				</div>
				{
					section_final &&
					<div className="section-info__container--footer">
						<div className="text">
							<p>
								{section_final.description.description}
							</p>
						</div>
						<div className="cont-img">
							<img src={section_final.image} className="Perspectiva" alt="Perspectiva" />
							{
								section_final.link &&
								<a target="_blank" href={section_final.link.link.link} className="see-img">
									{section_final.link.label} <PlusOutlined />
								</a>
							}
						</div>
					</div>
				}

			</div>
		</div >
	);
}