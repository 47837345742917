import React, { useState } from "react";

import app from "../../../assets/images/app.png";
import tms from "../../../assets/images/tms.png";

export const SectionDescription = ({ apps }) => {

	return (
		<div className="section-description">
			<div className="section-description__container">
				{
					apps[0] &&
					<div className="section-description__container--content bottom">
						<div className="center">
							<div className="box">
								<img src={apps[0].multimedia_footer.src} className="img" />
							</div>
							<div className="box">
								<h2>{apps[0].name}</h2>
								<p>{apps[0].description}</p>
							</div>
						</div>
					</div>
				}
				{
					apps[1] &&
					<div className="section-description__container--content top">
						<div className="center">
							<div className="box">
								<h2>{apps[1].name}</h2>
								<p>{apps[1].description}</p>
							</div>
							<div className="box">
								<img src={apps[1].multimedia_footer.src} className="img" />
							</div>
						</div>
					</div>
				}

				<div className="msj">
					<div className="msj--text">
						<h6>
							Movemos LATAM
						</h6>
						<h5>
							¡Seguimos evolucionando y mejorando nuestra tecnología!
						</h5>
					</div>
				</div>
			</div>
		</div>
	);
}